import React from 'react';

const Home = () => (
  <div className="mt-24">

    <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg rounded-2xl p-6 m-3">
      <div className="flex justify-center">
        <p className="text-xl font-semibold">Velkomsttekst</p>
      </div>
      <div className="flex justify-center">
        <div className="mt-8 mx-[20%]">
          <div className="mt-8">
            <p className="font-semibold">
              Her kan du oprette dine tilmeldte klasser og skoler og sende links til dine lærere.

            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Home;
