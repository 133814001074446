import { MdAddCircle, MdKeyboardReturn } from 'react-icons/md';
import React from 'react';

const Header = ({ category, title, canCreate, handleCreate, handleGoBack }) => (
  <div className=" mb-10">
    <p className="text-lg text-gray-400">{category}</p>
    <p className="text-3xl font-extrabold tracking-tight text-slate-900">
      {title}
    </p>
    <div className=" flex justify-start space-x-1">

      {canCreate
        ? (
          <div>
            <button
              type="button"
              style={{ background: '#71A92C' }}
              className="text-white py-1 px-2 capitalize rounded-2xl"
              onClick={handleCreate}
            >
              <MdAddCircle style={{ height: '30px', width: '30px' }} />
            </button>
          </div>
        )
        : <div />}
      {handleGoBack
        && (
          <div>
            <button
              type="button"
              style={{ background: 'orange' }}
              className="text-white py-1 px-2 capitalize rounded-2xl"
              onClick={handleGoBack}
            >
              <MdKeyboardReturn style={{ height: '30px', width: '30px' }} />
            </button>
          </div>
        )}
    </div>

  </div>
);

export default Header;
