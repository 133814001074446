/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-indent */
import { MdDelete, MdClose } from 'react-icons/md';
import React from 'react';

const DeleteClassroom = ({ handleDelete, handleGoBack }) => (
    <div className="blanker">
        <div>
            <button onClick={() => handleGoBack()} className="fixed top-6 right-8 text-white hover:text-amber-500 text-7xl font-semibold duration-300" type="button">
                <MdClose style={{ height: '60px', width: '60px' }} />
            </button>
            <div className="flex flex-nowrap justify-center w-1/3 m-2 md:m-10 mt-8 mb-8 p-2 md:p-10 bg-white rounded-3xl modal">
                <p className="text-2xl font-extrabold tracking-tight text-slate-900">
                    Er du sikker på, at du vil slette dette klasse?
                </p>
                <div className="flex justify-center w-full px-3 mb-6 md:mb-0">

                    <button onClick={() => handleDelete()} className="w-1/2 pt-3 pb-2.5 rounded-lg  text-white  text-md mt-8 ml-2" style={{ backgroundColor: 'rgb(26, 151, 245)' }} type="button">
                        Slet
                    </button>
                    <button onClick={() => handleGoBack()} className="w-1/2 pt-3 pb-2.5 rounded-lg  text-white  text-md mt-8 ml-2" style={{ backgroundColor: 'rgb(26, 151, 245)' }} type="button">
                        Afbestille
                    </button>
                </div>
            </div>
        </div>
    </div>
);

export default DeleteClassroom;
