/* eslint-disable quotes */
/* eslint-disable react/self-closing-comp */
/* eslint-disable jsx-quotes */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { GridComponent, ColumnsDirective, ColumnDirective, Resize, Sort, ContextMenu, Filter, Page, Inject, Search } from '@syncfusion/ej2-react-grids';
import { collection, query, orderBy, onSnapshot, Timestamp, doc, deleteDoc } from 'firebase/firestore';
import { MdControlPointDuplicate, MdDelete, MdAddCircle, MdModeEdit, MdPageview, MdKeyboardReturn, MdEmail, MdClose } from 'react-icons/md';
import { db, logout } from '../data/firebase';
import { contextMenuItems } from '../data/dummy';
import { Header, CreateCalendar, DeleteCalendar } from '../components';

const gridCalendarActions = (props) => (
  <div className="flex justify-center space-x-3">
    <div>
      <button
        type="button"
        style={{ background: '#AAAFB4' }}
        className="text-white py-1 px-2 capitalize rounded-2xl text-md"
      // onClick={props.HandleEdit}
      >
        <MdModeEdit style={{ height: '30px', width: '30px' }} />
      </button>
    </div>
    <div>
      <button
        type="button"
        style={{ background: '#AAAFB4' }}
        className="text-white py-1 px-2 capitalize rounded-2xl text-md"
      //  onClick={props.HandleView}
      >
        <MdPageview style={{ height: '30px', width: '30px' }} />
      </button>
    </div>
    <div>
      <button
        type="button"
        style={{ background: '#AAAFB4' }}
        className="text-white py-1 px-2 capitalize rounded-2xl text-md"
      //  onClick={props.HandleDelete}
      >
        <MdDelete style={{ height: '30px', width: '30px' }} />
      </button>
    </div>
  </div>
);
export const calendarsGrid = [
  {
    field: 'Title',
    headerText: 'Titel',
    width: '150',
    textAlign: 'Left',
  },
  {
    headerText: 'Valgmuligheder',
    template: gridCalendarActions,
    textAlign: 'Center',
    width: '120',
  },
  {
    field: 'ChangeDate',
    headerText: 'Sidst ændret',
    width: '135',
    format: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    },
    textAlign: 'Center',
  },
];

const Calendars = () => {
  const editing = { allowDeleting: true, allowEditing: true };
  const [calendars, setCalendars] = useState([]);
  const [isCreating, setIsCreating] = useState(false);
  const [isViewing, setIsViewing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedCalendar, setSelectedCalendar] = useState('');

  const beginEdit = (id) => {
    console.log('Edit Item function call');
  };
  const viewItem = (id) => {
    console.log(`View Item ID: ${id}`);
  };
  const createItem = () => {
    console.log('Create Item function call');
    setIsCreating(true);
  };
  const saveItem = () => {
    console.log('Save Item function call');
  };
  const goBack = (id) => {
    console.log('Go back');
    setIsViewing(false);
    setIsCreating(false);
    setIsDeleting(false);
    setIsEditing(false);
  };
  const beginDelete = (id) => {
    setSelectedCalendar(id);
    setIsDeleting(true);
  };
  const deleteItem = async () => {
    const docRef = doc(db, 'calendars', selectedCalendar);
    try {
      await deleteDoc(docRef);
    } catch (err) {
      alert(err);
    }
    goBack();
  };
  useEffect(() => {
    const q = query(collection(db, 'calendars'));
    onSnapshot(q, (querySnapshot) => {
      setCalendars(
        querySnapshot.docs.map((document) => ({
          id: document.id,
          Title: document.data().title,
          Description: document.data().calendarRef,
          ChangeDate: new Date(document.data().changeTimestamp.seconds * 1000).toLocaleString(),
          HandleDelete: () => beginDelete(document.id),
          HandleEdit: () => beginEdit(document.id),
          HandleView: () => viewItem(document.id),
        })),
      );
    });
  }, []);
  /* if (isCreating) {
    return (
      <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
        <Header category="Calendars" title="Create new calendar" handleGoBack={() => goBack()} />
        <CreateCalendar handleGoBack={() => goBack()} />
      </div>

    );
  } */
  /*
  if (isDeleting) {
    return (
      <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
        <Header category="Dashboard" title="Calendars" handleCreate={() => createItem()} />
        <GridComponent
          id="gridData"
          dataSource={calendars}
          allowPaging
          allowSorting
          allowSearch
          editSettings={editing}
        >
          <ColumnsDirective>
            {calendarsGrid.map((item, index) => <ColumnDirective key={index} {...item} />)}
          </ColumnsDirective>
          <Inject services={[Page, Search]} />
        </GridComponent>
        <DeleteCalendar handleGoBack={() => goBack()} handleDelete={() => deleteItem()} />
      </div>
    );
  } */
  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="Menu" title="Kalendere" canCreate={false} handleCreate={() => createItem()} />
      <GridComponent
        id="gridData"
        dataSource={calendars}
        allowPaging
        allowSorting
        allowSearch
        editSettings={editing}
      >
        <ColumnsDirective>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          {calendarsGrid.map((item, index) => <ColumnDirective key={index} {...item} />)}
        </ColumnsDirective>
        <Inject services={[Page, Search]} />
      </GridComponent>
    </div>
  );
};

export default Calendars;
