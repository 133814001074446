/* eslint-disable no-plusplus */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-trailing-spaces */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable react/jsx-first-prop-new-line */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useRef, useEffect } from 'react';
import { addDoc, collection, Timestamp, query, orderBy, onSnapshot, setDoc, doc } from 'firebase/firestore';
import { MdClose, MdOutlinePriorityHigh, MdDone } from 'react-icons/md';
import emailjs from '@emailjs/browser';
import { Header } from '.';
import { db } from '../data/firebase';

const SendClassroom = (props) => {
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [openFailureModal, setOpenFailureModal] = useState(false);
  const [openForm, setOpenForm] = useState(true);
  const [pin, setPin] = useState('');
  const [calendarName, setCalendarName] = useState('');
  const [classroomID, setClassroomID] = useState(props.selectedClassroom);
  const [emailToSend, setEmailToSend] = useState('');
  const form = useRef();

  function checkIfFilledOut() {
    if (!emailToSend.includes('.')) return false;
    return true;
  }
  useEffect(() => {
    const q = query(doc(db, 'classrooms', classroomID));
    onSnapshot(q, (querySnapshot) => {
      setPin(querySnapshot.data().pin);
      const c = query(doc(db, 'calendars', querySnapshot.data().calendarRef));
      onSnapshot(c, (calendarSnapshot) => {
        setCalendarName(calendarSnapshot.data().title);
      });
    });
  }, []);
  function isInteger(str) {
    if (typeof str !== 'string') {
      return false;
    }
    const num = Number(str);

    if (Number.isInteger(num)) {
      return true;
    }
    if (num === 0) {
      return true;
    }

    return false;
  }
  function checkNumberFieldLength(elem) {
    if (elem.value.length > 4) {
      // eslint-disable-next-line no-param-reassign
      elem.value = elem.value.slice(0, 4);
    }
  }
  const onPinInput = (string) => {
    if (isInteger(string)) {
      setPin(string);
    }
  };
  const sendEmail = (e) => {
    e.preventDefault();
    const templateParams = {
      messageCalendar: `Link: https://julenbaglaagerne.dk/${classroomID}`,
      messagePIN: `PIN: ${pin}`,
      email: emailToSend,
    };
    emailjs.send('service_4yfgnmb', 'template_sp0df4h', templateParams, 'IMqDLeIE-IIn7XgwU')
      .then((result) => {
        setOpenForm(false);
        setOpenSuccessModal(true);
      }, (error) => {
        setOpenForm(false);
        setOpenFailureModal(true);
      });
  };
  function closeFailureModal() {
    setOpenSuccessModal(false);
    setOpenForm(true);
    setOpenFailureModal(false);
  }

  return (
    <div className="blanker">
      {openForm && (
        <div>
          <button onClick={() => props.handleGoBack()} className="fixed top-6 right-8 text-white hover:text-amber-500 text-7xl font-semibold duration-300" type="button">
            <MdClose style={{ height: '60px', width: '60px' }} />
          </button>
          <div className="w-1/3 m-2 md:m-10 mt-8 mb-8 p-2 md:p-10 bg-white rounded-3xl modal">
            <Header category="Klasser og skoler" title="Send kalender" />
            <form ref={form} onSubmit={sendEmail} className="w-full">
              <div className="flex  -mx-3 mb-6">

                <div className="w-full md:w-full px-3">
                  <label className="text-1xl tracking-tight text-slate-900" htmlFor="grid-first-name">
                    Kontroller om informationen du har skrevet nedenunder er korrekt
                  </label>
                </div>
              </div>
              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3">
                  <label className="text-2xl  bold tracking-tight text-slate-900" htmlFor="grid-password">
                    Send til
                  </label>
                  <input
                    onChange={(e) => setEmailToSend(e.target.value)}
                    value={emailToSend}
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200
                    rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-contact-name"
                    type="email"
                    placeholder="nn@123.dk"
                  />
                </div>

                <div className="w-full px-3 mt-2">
                  <label className="text-2xl tracking-tight text-slate-900" htmlFor="grid-contact-name">
                    PIN
                  </label>
                </div>
                <div className="w-full px-3 mt-1">
                  <label className="text-1xl tracking-tight text-slate-900" htmlFor="grid-contact-name">
                    {pin}
                  </label>
                </div>
                <div className="w-full px-3 mt-3">
                  <label className="text-2xl tracking-tight text-slate-900" htmlFor="grid-contact-name">
                    Kalender
                  </label>
                </div>
                <div className="w-full px-3 mt-1">
                  <label className="text-1xl tracking-tight text-slate-900" htmlFor="grid-contact-name">
                    {calendarName}
                  </label>
                </div>
                <div className="w-full px-3 mt-3">
                  <label className="text-2xl tracking-tight text-slate-900" htmlFor="grid-contact-name">
                    Link
                  </label>
                </div>
                <div className="w-full px-3 mt-1">
                  <label className="text-1xl tracking-tight text-slate-900" htmlFor="grid-contact-name">
                    {`http://julenbaglaagerne.dk/${classroomID}`}
                  </label>
                </div>

              </div>
              <div className="flex justify-center w-full px-3 mb-6 md:mb-0">

                <button className="w-full pt-3 pb-2.5 rounded-lg  text-white  text-md mt-8 ml-2" style={{ backgroundColor: 'rgb(26, 151, 245)' }} type="submit">
                  Bekræft
                </button>

              </div>
            </form>

          </div>
        </div>
      )}
      {openSuccessModal && (
        <div>
          <button onClick={() => props.handleGoBack()} className="fixed top-6 right-8 text-white hover:text-amber-500 text-7xl font-semibold duration-300" type="button">
            <MdClose style={{ height: '60px', width: '60px' }} />
          </button>
          <div className="flex flex-nowrap justify-center w-1/3 m-2 md:m-10 mt-8 mb-8 p-2 md:p-10 bg-white rounded-3xl modal">
            <MdDone style={{ height: '30px', width: '30px' }} />
            <p className="text-2xl font-extrabold tracking-tight text-slate-900">
              Mail er blevet sendt
            </p>
          </div>
        </div>
      )}
      {openFailureModal && (
        <div>
          <button onClick={() => closeFailureModal()} className="fixed top-6 right-8 text-white hover:text-amber-500 text-7xl font-semibold duration-300" type="button">
            <MdClose style={{ height: '60px', width: '60px' }} />
          </button>
          <div className="flex flex-nowrap justify-center w-1/3 m-2 md:m-10 mt-8 mb-8 p-2 md:p-10 bg-white rounded-3xl modal">
            <MdOutlinePriorityHigh style={{ height: '30px', width: '30px' }} />
            <p className="text-2xl font-extrabold tracking-tight text-slate-900">
              Mail blev ikke sendt
            </p>
          </div>
        </div>
      )}

    </div>
  );
};
export default SendClassroom;
