
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-return-assign */
/* eslint-disable function-paren-newline */
/* eslint-disable comma-dangle */
/* eslint-disable indent */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/control-has-associated-label */
import { MdControlPointDuplicate, MdDelete, MdAddCircle, MdModeEdit, MdPageview, MdKeyboardReturn, MdEmail, MdClose } from 'react-icons/md';
import React, { useState, useEffect, useRef } from 'react';
// import { L10n, setCulture } from '@syncfusion/ej2-base';
import { GridComponent, Inject, ColumnsDirective, ColumnDirective, Search, Page, Sort } from '@syncfusion/ej2-react-grids';
import { collection, query, orderBy, onSnapshot, Timestamp, doc, deleteDoc } from 'firebase/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Header, CreateClassroom, SendClassroom, DeleteClassroom } from '../components';
import { db, logout, auth } from '../data/firebase';
import '../classrooms.css';

/* L10n.load({
  'dk-DK': {
    pager: {
      currentPageInfo: '{0} af {1} Sider',
      totalItemsInfo: '({0} klasser )'
    }
  }
}); */
export const gridClassroomActions = (props) => (
  <div className="flex justify-center space-x-3">
    <div>
      <button
        type="button"
        // style={{ background: '#FFD700' }}
        style={{ background: '#AAAFB4' }}
        className="text-white py-1 px-2 capitalize rounded-2xl text-md"
        onClick={props.HandleEdit}
      >
        <MdModeEdit style={{ height: '30px', width: '30px' }} />
      </button>
    </div>
    <div>
      <button
        type="button"
        // style={{ background: '#FF8200' }}
        style={{ background: '#AAAFB4' }}
        className="text-white py-1 px-2 capitalize rounded-2xl text-md"
        onClick={props.HandleView}
      >
        <MdPageview style={{ height: '30px', width: '30px' }} />
      </button>
    </div>
    <div>
      <button
        type="button"
        style={{ background: '#0E86D4' }}
        className="text-white py-1 px-2 capitalize rounded-2xl text-md"
        onClick={props.HandleSend}
      >
        <MdEmail style={{ height: '30px', width: '30px' }} />
      </button>
    </div>
    <div>
      <button
        type="button"
        style={{ background: '#ff2a26' }}
        className="text-white py-1 px-2 capitalize rounded-2xl text-md"
        onClick={props.HandleDelete}
      >
        <MdDelete style={{ height: '30px', width: '30px' }} />
      </button>
    </div>
  </div>

);

export const classroomGrid = [
  {
    field: 'Name',
    headerText: 'Titel',
    width: '150',
    textAlign: 'Left',
    fontSize: '50px'
  },
  {
    headerText: 'Valgmuligheder',
    width: '150',
    template: gridClassroomActions,
    textAlign: 'Center',
  },
  {
    field: 'ChangeDate',
    headerText: 'Sidst ændret',
    width: '135',
    format: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    },
    textAlign: 'Center',
  },

];

const Classrooms = () => {
  const [classrooms, setClassrooms] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [user, loading, error] = useAuthState(auth);
  const [isViewing, setIsViewing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [selectedPIN, setSelectedPIN] = useState('');
  const [selectedClassroom, setSelectedClassroom] = useState('');
  const sortingOptions = { columns: [{ field: 'Name', direction: 'Ascending', default: 'Ascending' }] };

  const beginEdit = (id) => {
    console.log('Edit Item function call');
  };
  const viewItem = (id) => {
    console.log(`View Item ID: ${id}`);
  };
  const createItem = () => {
    console.log('Create Item function call');
    setIsCreating(true);
  };
  const saveItem = () => {
    console.log('Save Item function call');
  };
  const sendItem = (id) => {
    console.log(`Send item id: ${id}`);
    setSelectedClassroom(id);
    setIsSending(true);
  };
  const goBack = (id) => {
    console.log('Go back');
    setIsViewing(false);
    setIsCreating(false);
    setIsDeleting(false);
    setIsEditing(false);
    setIsSending(false);
  };
  const beginDelete = (id) => {
    setSelectedClassroom(id);
    setIsDeleting(true);
  };
  const deleteItem = async () => {
    const docRef = doc(db, 'classrooms', selectedClassroom);
    try {
      await deleteDoc(docRef);
    } catch (err) {
      alert(err);
    }
    goBack();
  };
  function compare(a, b) {
    if (a.Name < b.Name) {
      return -1;
    }
    if (a.Name > b.Name) {
      return 1;
    }
    return 0;
  }
  useEffect(() => {
    const q = query(collection(db, 'classrooms'));
    onSnapshot(q, (querySnapshot) => {
      setClassrooms(querySnapshot.docs.map((document) => ({
        Name: `${document.data().school} ${document.data().className}`,
        id: document.id,
        CalendarRef: document.data().calendarRef,
        School: document.data().school,
        AdminRef: document.data().adminRef,
        City: document.data().city,
        ChangeDate: new Date(document.data().changeTimestamp.seconds * 1000).toLocaleString(),
        Pin: document.data().pin,
        Email: document.data().email,
        MainContact: document.data().mainContact,
        Progress: document.data().progress,
        HandleDelete: () => beginDelete(document.id),
        HandleEdit: () => beginEdit(document.id),
        HandleView: () => viewItem(document.id),
        HandleSend: () => sendItem(document.id),
      })
      ));
    });
    setTimeout(() => classrooms.sort(compare), 500);
  }, []);
  const toolbarOptions = ['Search'];
  const editing = { allowDeleting: true, allowEditing: true };

  if (isCreating) {
    return (
      <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
        <Header category="Klasser og skoler" title="Opret ny klasse" handleGoBack={() => goBack()} />
        <CreateClassroom handleGoBack={() => goBack()} handleSendItem={sendItem} />
      </div>

    );
  }
  if (isSending) {
    return (
      <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
        <Header category="Menu" title="Klasser og skoler" canCreate={true} handleCreate={() => createItem()} />
        <GridComponent
          locale="de-DE"
          id="gridData"
          dataSource={classrooms.filter((classroom) => classroom.AdminRef === user.uid)}
          width="auto"
          allowPaging
          allowSorting
          pageSettings={{ pageCount: 5 }}
          editSettings={editing}
          toolbar={toolbarOptions}
        >
          <ColumnsDirective>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            {classroomGrid.map((item, index) => <ColumnDirective key={index} {...item} />)}
          </ColumnsDirective>
          <Inject services={[Search, Page]} />
        </GridComponent>
        <SendClassroom handleGoBack={() => goBack()} selectedClassroom={selectedClassroom} />
      </div>
    );
  }
  if (isDeleting) {
    return (
      <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
        <Header category="Menu" title="Klasser og skoler" canCreate={true} handleCreate={() => createItem()} />
        <GridComponent
          id="gridData"
          locale="de-DE"
          dataSource={classrooms.filter((classroom) => classroom.AdminRef === user.uid)}
          width="auto"
          allowPaging
          allowSorting
          pageSettings={{ pageCount: 8, pageSize: 5 }}
          editSettings={editing}
          toolbar={toolbarOptions}
        >
          <ColumnsDirective>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            {classroomGrid.map((item, index) => <ColumnDirective key={index} {...item} />)}
          </ColumnsDirective>
          <Inject services={[Search, Page]} />
        </GridComponent>
        <DeleteClassroom handleGoBack={() => goBack()} handleDelete={() => deleteItem()} />
      </div>
    );
  }

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="Menu" title="Klasser og skoler" canCreate={true} handleCreate={() => createItem()} />
      <GridComponent
        id="gridData"
        locale="dk-DK"
        dataSource={classrooms.filter((classroom) => classroom.AdminRef === user.uid)}
        width="auto"
        allowPaging
        allowSorting
        pageSettings={{ pageCount: 8, pageSize: 5 }}
        editSettings={editing}
        toolbar={toolbarOptions}
      >
        <ColumnsDirective>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          {classroomGrid.map((item, index) => <ColumnDirective key={index} {...item} />)}
        </ColumnsDirective>
        <Inject services={[Search, Page, Sort]} />
      </GridComponent>
    </div>
  );
};
export default Classrooms;
