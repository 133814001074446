/* eslint-disable no-plusplus */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-trailing-spaces */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable react/jsx-first-prop-new-line */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useEffect } from 'react';
import { MdDelete, MdClose } from 'react-icons/md';
import { addDoc, collection, Timestamp, query, orderBy, onSnapshot, setDoc, doc, where, getDocs } from 'firebase/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// eslint-disable-next-line import/no-unresolved
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { db, auth } from '../data/firebase';

const CreateClassroom = (props) => {
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [openFailureModal, setOpenFailureModal] = useState(false);
  const [user, loading, error] = useAuthState(auth);
  const [className, setClassName] = useState('');
  const [school, setSchool] = useState('');
  const [city, setCity] = useState('');
  const [contactName, setContactName] = useState('');
  const [contactEmail, setEmail] = useState('');
  const [pin, setPin] = useState('');
  const [calendar, setCalendar] = useState('None');
  const [calendarOptions, setCalendarOptions] = useState([]);
  const [createdClassroomID, setCreatedClassroomID] = useState('');
  const [isCreating, setIsCreating] = useState(false);
  const element = <FontAwesomeIcon icon={faSpinner} spin />;
  function setCalendarOption(elem) {
    if (elem === 'None') {
      setCalendar('None');
    } else {
      for (let i = 0; i < calendarOptions.length; i++) {
        if (calendarOptions[i].title === elem) {
          setCalendar(calendarOptions[i].id);
          break;
        }
      }
    }
  }
  const q = query(collection(db, 'calendars'));
  onSnapshot(q, (querySnapshot) => {
    setCalendarOptions(querySnapshot.docs.map((document) => ({
      title: document.data().title,
      id: document.id,
    })));
  });
  function checkIfFilledOut() {
    if (className.length < 1) { return false; }
    if (school.length < 1) { return false; }
    if (contactName.length < 1) { return false; }
    if (contactEmail.length < 1) { return false; }
    if (calendar == null && pin.length !== 4) { return false; }

    return true;
  }

  const handleCreate = async () => {
    if (checkIfFilledOut()) {
      console.log('Data was filled out');
      setIsCreating(true);
      try {
        const newClassroom = await addDoc(collection(db, 'classrooms'), {
          className,
          pin,
          changeTimestamp: Timestamp.now(),
          city,
          calendarRef: calendar,
          email: contactEmail,
          mainContact: contactName,
          school,
          adminRef: user.uid,
        });
        setCreatedClassroomID(newClassroom.id);
        for (let i = 1; i < 25; i++) {
          await setDoc(doc(db, `classrooms/${newClassroom.id}/progress`, `/${i}`), {
            wasOpened: false,
          });
        }
        setOpenSuccessModal(true);
        setIsCreating(false);
      } catch (err) {
        setOpenFailureModal(true);
        setIsCreating(false);
      }
    } else {
      setOpenFailureModal(true);
      setIsCreating(false);
    }
  };

  function isInteger(str) {
    if (typeof str !== 'string') {
      return false;
    }
    const num = Number(str);

    if (Number.isInteger(num)) {
      return true;
    }
    if (num === 0) {
      return true;
    }

    return false;
  }
  function checkNumberFieldLength(elem) {
    if (elem.value.length > 4) {
      // eslint-disable-next-line no-param-reassign
      elem.value = elem.value.slice(0, 4);
    }
  }
  const onPinInput = (string) => {
    if (isInteger(string)) {
      setPin(string);
    }
  };
  const sendCreatedCalendar = () => {
    props.handleGoBack();
    props.handleSendItem(createdClassroomID);
  };
  return (
    <div>

      <form className="w-full">
        <div className="flex  -mx-3 mb-6">
          <div className="w-full md:w-1/3 px-4">
            <label className="text-2xl tracking-tight text-slate-900" htmlFor="grid-first-name">
              Klassenavn
            </label>
            <input
              onChange={(e) => setClassName(e.target.value)}
              value={className}
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded 
          py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-first-name" type="text" placeholder="4A" />
          </div>

          <div className="w-full md:w-1/3 px-4">
            <label className="text-2xl tracking-tight text-slate-900" htmlFor="grid-last-name">
              Skole
            </label>
            <input
              onChange={(e) => setSchool(e.target.value)}
              value={school}
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 
          rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-middle-name" type="text" placeholder="Ribe Efterskole" />
          </div>

          <div className="w-full md:w-1/3 px-4">
            <label className="text-2xl tracking-tight text-slate-900" htmlFor="grid-city">
              By
            </label>
            <input
              onChange={(e) => setCity(e.target.value)}
              value={city}
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded 
          py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-city" type="text" placeholder="Ribe" />
            <p className="text-gray-500 text-xs italic">Optional.</p>
          </div>

        </div>
        <div className="flex flex-wrap -mx-3 mb-6">

          <div className="w-1/2 px-3">
            <label className="text-2xl tracking-tight text-slate-900" htmlFor="grid-password">
              Kontaktperson
            </label>
            <input
              onChange={(e) => setContactName(e.target.value)}
              value={contactName}
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 
          rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-contact-name" type="text" placeholder="Niels Nielsen" />
          </div>

          <div className="w-1/2 px-3">
            <label className="text-2xl tracking-tight text-slate-900" htmlFor="grid-contact-name">
              Email
            </label>
            <input
              onChange={(e) => setEmail(e.target.value)}
              value={contactEmail}
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 
          rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-contact-mail" type="mail" placeholder="nn@123.dk" />
          </div>

        </div>
        <div className="flex flex-wrap -mx-3 mb-2">

          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
            <label className="text-2xl tracking-tight text-slate-900" htmlFor="grid-state">
              Vælg kalender
            </label>
            <div className="relative">
              <select onChange={(e) => setCalendarOption(e.target.value)} className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700
             py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-state">
                <option>None</option>
                {calendarOptions !== null
                  && calendarOptions?.map((calendarOption) => (
                    <option key={calendarOption.id}>{calendarOption.title}</option>
                  ))}

              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
              </div>
            </div>
          </div>

          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
            <label className="text-2xl tracking-tight text-slate-900" htmlFor="grid-zip">
              PIN
            </label>
            <input
              onChange={(e) => onPinInput(e.target.value)}
              onInput={(e) => checkNumberFieldLength(e.target)}
              value={pin}
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-zip" type="text" placeholder="0000" />
          </div>

          <div className="flex justify-end w-full md:w-1/3 px-3 mb-6 md:mb-0">
            {isCreating && (
              <div className="flex justify-end w-full">
                <div className="flex w-1/2 justify-center pt-3 pb-2.5 rounded-lg  text-white  text-md mt-8 ml-2" style={{ backgroundColor: 'rgb(26, 151, 245)' }}>
                  {element}
                </div>
              </div>
            )}
            {!isCreating && (
              <div className="flex justify-end w-full h-full">
                <button onClick={() => handleCreate()} className="flex w-1/2 justify-center pt-3 pb-2.5 rounded-lg  text-white  text-md mt-8 ml-2" style={{ backgroundColor: 'rgb(26, 151, 245)' }} type="button">
                  Gem klasse
                </button>
              </div>
            )}

          </div>
        </div>
      </form>
      {openFailureModal
        && (
          <div className="blanker">
            <div>
              <button onClick={() => setOpenFailureModal(false)} className="fixed top-6 right-8 text-white hover:text-amber-500 text-7xl font-semibold duration-300" type="button">
                <MdClose style={{ height: '60px', width: '60px' }} />
              </button>
              <div className="flex flex-nowrap justify-center w-1/3 m-2 md:m-10 mt-8 mb-8 p-2 md:p-10 bg-white rounded-3xl modal">
                <p className="text-2xl font-extrabold tracking-tight text-slate-900">
                  Kontroller at alt nødvendig information er udfyldt.
                </p>
                <div className="flex justify-center w-full px-3 mb-6 md:mb-0">

                  <button onClick={() => setOpenFailureModal(false)} className="w-1/2 pt-3 pb-2.5 rounded-lg  text-white  text-md mt-8 ml-2" style={{ backgroundColor: 'rgb(26, 151, 245)' }} type="button">
                    Fortsæt
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      {openSuccessModal
        && (
          <div className="blanker">
            <div>
              <button onClick={() => props.handleGoBack()} className="fixed top-6 right-8 text-white hover:text-amber-500 text-7xl font-semibold duration-300" type="button">
                <MdClose style={{ height: '60px', width: '60px' }} />
              </button>
              <div className="flex flex-nowrap justify-center w-1/3 m-2 md:m-10 mt-8 mb-8 p-2 md:p-10 bg-white rounded-3xl modal">
                <p className="text-2xl font-extrabold tracking-tight text-slate-900">
                  Klasse og skole er blevet tilføjet og gemt
                </p>
                <div className="flex justify-center w-full px-3 mb-6 md:mb-0">

                  <button onClick={() => props.handleGoBack()} className="w-1/2 pt-3 pb-2.5 rounded-lg  text-white  text-md mt-8 ml-2" style={{ backgroundColor: 'rgb(26, 151, 245)' }} type="button">
                    Fortsæt
                  </button>
                  {calendar !== 'None'
                    && (
                      <button onClick={() => sendCreatedCalendar()} className="w-1/2 pt-3 pb-2.5 rounded-lg  text-white  text-md mt-8 ml-2" style={{ backgroundColor: 'rgb(26, 151, 245)' }} type="button">
                        Send kalender
                      </button>
                    )}

                </div>
              </div>
            </div>
          </div>
        )}
    </div>
  );
};
export default CreateClassroom;
