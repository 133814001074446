/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable quotes */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
import { FiSettings } from 'react-icons/fi';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Navbar, Footer, Sidebar, ThemeSettings } from './components';
import { Home, Calendars, Classrooms } from './pages';
import './App.css';
import { auth, logout, logInWithEmailAndPassword } from './data/firebase';
import { useStateContext } from './contexts/ContextProvider';
import Login from './pages/Login';

const App = () => {
  const { setCurrentColor, setCurrentMode, currentMode, activeMenu, currentColor, themeSettings, setThemeSettings } = useStateContext();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [user, loading] = useAuthState(auth);
  useEffect(() => {
    if (loading) {
      setIsLoggedIn(false);
    }
    if (user) setIsLoggedIn(true);
  }, [user, loading]);
  useEffect(() => {
    const currentThemeColor = localStorage.getItem('colorMode');
    const currentThemeMode = localStorage.getItem('themeMode');
    if (currentThemeColor && currentThemeMode) {
      setCurrentColor(currentThemeColor);
      setCurrentMode(currentThemeMode);
    }
  }, []);
  function signOutUser() {
    setIsLoggedIn(false);
    logout();
  }
  if (isLoggedIn) {
    return (
      <div className={currentMode === 'Dark' ? 'dark' : ''}>
        <BrowserRouter>
          <div className="flex relative dark:bg-main-dark-bg">
            {activeMenu ? (
              <div className="w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white ">
                <Sidebar />
              </div>
            ) : (
              <div className="w-0 dark:bg-secondary-dark-bg">
                <Sidebar />
              </div>
            )}
            <div
              className={
                activeMenu
                  ? 'dark:bg-main-dark-bg  bg-main-bg min-h-screen md:ml-72 w-full  '
                  : 'bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2 '
              }
            >
              <div className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full ">
                <Navbar logout={() => signOutUser()} />
              </div>
              <div>
                {themeSettings && (<ThemeSettings />)}

                <Routes>
                  <Route path="/" element={(<Home />)} />
                  <Route path="/home" element={(<Home />)} />
                  <Route path="/calendars" element={<Calendars />} />
                  <Route path="/classrooms" element={<Classrooms />} />
                </Routes>
              </div>
              <Footer />
            </div>
          </div>
        </BrowserRouter>
      </div>
    );
  }
  return (
    <div className="login flex justify-center">
      <div className="login_logo  m-4 mt-4 mb-4 p-2 bg-white rounded-2xl">
        <p className="text-3xl m-6 text-left  font-extrabold tracking-tight text-slate-900">
          Landsnetvaerket af folkekirkelige skoletjenester
        </p>
      </div>
      <div className="login_container w-1/4 m-4 mt-4 mb-4 p-2 bg-white rounded-2xl">
        <div className="w-full h-1/4 mt-1 mb-4 p-4 pl-8 pr-8 pt-6">
          <label className="text-xl tracking-tight text-slate-900">Login</label>
        </div>
        <div className="w-full h-1/4 p-4 pl-8 pr-8">
          <label className="text-lg tracking-tight text-slate-900">Email</label>
          <input
            type="text"
            className="appearance-none block w-full bg-white-200 text-gray-700 border border-slate-400 border-black-600 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-gray-200 focus:border-gray-700"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="E-mail Address"
          />
        </div>
        <div className="w-full h-1/4 p-4 pl-8 pr-8">
          <label className="text-lg tracking-tight text-slate-900">Password</label>
          <input
            type="password"
            className="appearance-none block w-full bg-white-200 text-gray-700 border border-slate-400 border-black-600 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-gray-200 focus:border-gray-700"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
          />
        </div>
        <div className="flex justify-center w-full h-1/4 p-4 pl-8 pr-8">
          <button
            className="text-xl flex w-full justify-center m-2 pt-3 pb-2.5 rounded-lg  text-white"
            style={{ backgroundColor: 'rgb(26, 151, 245)' }}
            type="button"
            onClick={() => logInWithEmailAndPassword(email, password)}
          >
            Login
          </button>
        </div>

      </div>
    </div>
  );
};

export default App;
