/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from 'react';
import { MdOutlineCancel } from 'react-icons/md';
import { useAuthState } from 'react-firebase-hooks/auth';
import { query, collection, getDocs, where } from 'firebase/firestore';
import { db, auth } from '../data/firebase';
import { LogoutButton, Button } from '.';
import { useStateContext } from '../contexts/ContextProvider';
import avatar from '../data/userAvatar.png';

const UserProfile = (props) => {
  const [userName, setUserName] = useState(props.userName);
  const [email, setEmail] = useState(props.email);
  const [role, setRole] = useState(props.role);

  const { currentColor } = useStateContext();
  return (
    <div className="nav-item absolute right-1 top-16 bg-white dark:bg-[#42464D] p-8 rounded-lg w-96">
      <div className="flex justify-between items-center">
        <p className="font-semibold text-lg dark:text-gray-200">Brugerprofil</p>
        <Button
          icon={<MdOutlineCancel />}
          color="rgb(153, 171, 180)"
          bgHoverColor="light-gray"
          size="2xl"
          borderRadius="50%"
        />
      </div>
      <div className="flex gap-5 items-center mt-6 border-color border-b-1 pb-6">
        <img
          className="rounded-full h-24 w-24"
          src={avatar}
          alt="user-profile"
        />
        <div>
          <p className="font-semibold text-xl dark:text-gray-200"> {userName} </p>
          <p className="text-gray-500 text-sm dark:text-gray-400">  {role}   </p>
          <p className="text-gray-500 text-sm font-semibold dark:text-gray-400"> {email} </p>
        </div>
      </div>
      <div className="mt-5">

        <LogoutButton
          color="white"
          bgColor={currentColor}
          onClickAction={props.logoutUser}
          text="Logout"
          borderRadius="10px"
          width="full"
        />
      </div>
    </div>

  );
};

export default UserProfile;
